import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import VideoEditorComponent from 'Components/VideoEditor';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { VideosApi, GraphicAssetsApi, UploadApi, MerchantAssetsApi } from 'Api';
import Loader from 'Components/Loader/LineLoader';
import mobilecheck from 'Utils/mobilecheck';
import { apiError } from 'Utils/alert';
import { attemptToDoRequest } from 'Utils/api';
import { confirmYesNo } from 'Components/ConfirmModal';

class VideoEditor extends Component {
  state = {};
  get videoId() {
    const {
      match: {
        params: { videoId },
      },
    } = this.props;
    return videoId;
  }
  componentWillMount() {
    this.loadVideo(this.videoId);
  }

  loadVideo = async (videoId) => {
    try {
      const video = await attemptToDoRequest(
        () => VideosApi.getById(videoId),
        1000
      );
      this.setState({
        video,
      });
    } catch (e) {
      //
    }
  };

  closeEditor = async () => {
    if (!(await confirmYesNo('Are you sure that you want to close editor?'))) {
      return;
    }
    this.props.Routing.push('/library/my-library/videos');
  };

  saveOverlay = async (overlay, selectedFrame) => {
    return VideosApi.updateOverlay(
      this.videoId,
      overlay,
      selectedFrame ? selectedFrame.id : null
    );
  };

  saveOverlayAndMoveForward = async (overlay, selectedFrame) => {
    try {
      await this.saveOverlay(overlay, selectedFrame);
      await new Promise((r) => {
        setTimeout(() => {
          r();
        }, 2000); //dirty hack, wait 2 seconds in order to make sure projection has been completed
      });
      setTimeout(
        () => this.props.Routing.push(`/video/settings/${this.videoId}`),
        100
      );
    } catch (e) {
      apiError(e);
    }
  };

  uploadAssets = async (assets) => {
    const url = await UploadApi.uploadImage(assets[0]);
    await MerchantAssetsApi.create(url);
    return url;
  };

  deleteAsset = async (asset) => await MerchantAssetsApi.delete(asset.url);

  loadUploads = async (offset, pageSize) => {
    const page = Math.floor(offset / pageSize);
    const { data } = await MerchantAssetsApi.list(page, pageSize);
    const { profile_image } = this.props.ProfileStore.currentProfile;
    return (offset === 0 ? [{ title: '', url: profile_image }] : []).concat(
      data.map((record) => ({ title: '', url: record.url }))
    );
  };

  loadAssets = async (offset, pageSize, filters, searchKey) => {
    if (filters && filters.find((filter) => filter.filter === 'uploads')) {
      return this.loadUploads(offset, pageSize);
    }

    const resolvedFilters = (filters || [])
      .map(({ filter, value }) => {
        return [filter, value];
      })
      .reduce(
        (prev, [filter, value]) => {
          prev[filter] = value;
          return prev;
        },
        { type: 'overlay' }
      );

    const page = Math.floor(offset / pageSize);

    const { data } = await GraphicAssetsApi.find(
      { ...resolvedFilters, searchKey },
      page,
      pageSize
    );
    return data.map((record) => ({ title: '', url: record.url }));
  };

  render() {
    const { video } = this.state;
    if (!video) {
      return <Loader />;
    }
    const {
      frameId,
      url,
      overlay,
      metadata: { scriptText, templateId },
    } = this.state.video;
    if (mobilecheck()) {
      return <Redirect to={{ pathname: `/video/settings/${video.id}` }} />;
    }
    return (
      <VideoEditorComponent
        onClose={this.closeEditor}
        videoSrc={[`${url}.mp4`, url]}
        scriptText={scriptText || ''}
        assetsSource={this.loadAssets}
        supportSearch={true}
        assetsFilters={[
          {
            title: 'my uploads',
            filter: 'uploads',
            value: 1,
            uploadAssets: this.uploadAssets,
            deleteAsset: this.deleteAsset,
          },
          {
            title: 'creative additions',
            filter: 'type',
            value: 'treatment',
            supportSearch: true,
          },
        ].concat(
          templateId
            ? [
                {
                  title: 'suggested',
                  filter: 'messageTemplateId',
                  value: templateId,
                  supportSearch: true,
                },
              ]
            : []
        )}
        overlay={
          overlay || {
            version: '2.4.4',
            objects: [],
          }
        }
        onSave={this.saveOverlayAndMoveForward}
        onAutoSave={this.saveOverlay}
        enableWindowsChromeWorkaround={true}
        selectedFrame={frameId}
      />
    );
  }
}

VideoEditor.propTypes = {
  match: ReactPropTypes.object,
  Routing: PropTypes.observableObject,
  ProfileStore: PropTypes.observableObject,
};

export default inject(
  'Routing',
  'ProfileStore'
)(observer(withRouter(VideoEditor)));
