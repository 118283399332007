import request from './request';
import { limit, toSearchQuery, merge } from './helpers';

export default {
  get: (search = null, page = 0, limitBy = 10) =>
    request.get(
      `/video?${merge(limit(limitBy, page), toSearchQuery({ search }))}`
    ),
  getCurated: (search = null, page = 0, limitBy = 10) =>
    request.get(
      `/video/curated?${merge(limit(limitBy, page), toSearchQuery({ search }))}`
    ),
  getById: async videoId => {
      const video = await request.get(`/video/get/${videoId}`)
      if (!!video.overlay) {
          if (typeof video.overlay === 'string' || video.overlay instanceof String) {
              video.overlay = JSON.parse(video.overlay)
          }
      } else {
          video.overlay = null
      }
      if (video.overlay && Object.keys(video.overlay).length === 0) {
          video.overlay = null
      }

      if (!!video.metadata) {
          if (typeof video.metadata === 'string' || video.metadata instanceof String) {
              video.metadata = JSON.parse(video.metadata)
          }
      } else {
          video.metadata = {}
      }

      return video;
  },
  record: (title, sourceUrl, metadata, videoFrameId) =>
    request.post(`/video`, { title, sourceUrl, metadata, videoFrameId }),
  copy: id => request.post(`/video/${id}/copy`),
  updateOverlay: (videoId, overlay, videoFrameId) =>
    request.put(`/video/${videoId}/customize`, { overlay, videoFrameId }),
  updateVideo: (
    videoId,
    title,
    caption,
    thumbnailUrl,
    description,
    landingPageImage
  ) =>
    request.put(`/video/${videoId}`, {
      title,
      caption,
      thumbnailUrl,
      description,
      landingPageImage
    }),
  getRecent: (search = null, page = 0, limitBy = 10, recordedAfter = null) =>
    request.get(
      `/video/recent?${merge(
        limit(limitBy, page),
        toSearchQuery({ search, recordedAfter })
      )}`
    ),
  delete: ids => request.delWithBody('/video/multiple', { ids }),
  compile: id => request.post('/video/compile', { id }),
  getCompiledVideoUrl: id => request.get(`/video/compiled/${id}`),
  getLogo: recordedBy => request.get(`/video/logo/${recordedBy}`),
  shareWithCustomers: (videoId, customers) =>
    request.post(`/video/${videoId}/shareWithCustomers`, { customers }),
  trackView: (videoId, referrer, customerId) =>
    request.sendBeacon(`/video/${videoId}/view`, { referrer, customerId }),
  reportPlayedSeconds: (videoId, playedSeconds, referrer, customerId) =>
    request.sendBeacon(`/video/${videoId}/playback`, {
      playedSeconds,
      referrer,
      customerId
    })
};
