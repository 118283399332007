/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Linkify from 'react-linkify';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import { VideosApi, UploadApi } from 'Api';
import { withRouter } from 'react-router-dom';
import Loader from 'Components/Loader/FacebookLoader';
import VideoPlayer from 'Components/VideoPlayer';
import SocialShare from './SocialShare';
import { Helmet } from 'react-helmet';
import Button from 'Components/Button';
import { ReactComponent as SunIcon } from 'Icons/sun.svg';
import { ReactComponent as MoonIcon } from 'Icons/moon.svg';
import { getTrackingReferrer, getQueryParams } from 'Utils/url';
import { getTextFromNode } from 'Components/PersonalizedTextEditor/Utils';
import { BRAND, MARKETING_HOST } from 'Utils/whitelabeling';

class VideoLanding extends React.Component {
  state = {};

  get queryParams() {
    const {
      match: { params }
    } = this.props;

    return params;
  }

  get videoId() {
    return this.queryParams.videoId;
  }

  get customerId() {
    return this.queryParams.customerId;
  }

  loadVideo = async (id, customerId) => {
    const video = await VideosApi.getById(id);
    this.setState({
      videoDescription: video.description,
      video: video
    });
    if (this.isPreviewMode) {
      return;
    }
    VideosApi.trackView(id, getTrackingReferrer(), customerId);
  };

  componentWillMount() {
    const queryParams = getQueryParams(this.props.Routing.location.search);
    this.isPreviewMode = Object.keys(queryParams).includes('preview');
    this.isCompiledMode = Object.keys(queryParams).includes('compiled');
    if (!this.isPreviewMode) {
      const self = this;
      window.onbeforeunload = window.onunload = function() {
        self.reportPlayedSeconds();
      };
      document.addEventListener('visibilitychange', function() {
        if (document.visibilityState === 'visible') {
          self.player && self.player.pause();
        } else {
          self.reportPlayedSeconds();
        }
      });
    }

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      // dark mode
      document.body.classList.add('dark-mode');
      this.setState({ darkMode: true });
    }

    window.addEventListener('resize', this.handleWindowResize, false);
    this.handleWindowResize();

    this.detectMerchantMode();
    this.loadVideo(this.videoId, this.customerId);
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { videoId }
      }
    } = nextProps;
    if (this.videoId === videoId) {
      return;
    }
    this.reportPlayedSeconds();
  }

  componentWillUnmount() {
    document.body.classList.remove('dark-mode');
    window.removeEventListener('resize', this.handleWindowResize);
    delete window.onbeforeunload;
    delete window.onunload;
    window.removeEventListener('visibilitychange', this.reportPlayedSeconds);
    this.reportPlayedSeconds();
  }

  detectMerchantMode = async () => {
    const token = await window.localStorage.getItem('jwt');
    this.setState({ merchantMode: !!token });
  };

  playedSeconds = 0;
  playbackStartedAt = null;

  onStartPlayback = () => {
    this.playbackStartedAt = new Date();
  };
  onPausePlayback = () => {
    this.updatePlayedSecondsCounter();
  };
  updatePlayedSecondsCounter = () => {
    if (!this.playbackStartedAt) {
      return;
    }
    this.playedSeconds +=
      (new Date().getTime() - this.playbackStartedAt.getTime()) / 1000;
    this.playbackStartedAt = null;
  };
  reportPlayedSeconds = () => {
    if (this.isPreviewMode) {
      return;
    }

    this.updatePlayedSecondsCounter();
    if (this.playedSeconds >= 1) {
      VideosApi.reportPlayedSeconds(
        this.videoId,
        this.playedSeconds,
        getTrackingReferrer(),
        this.customerId
      );
    }
    this.playedSeconds = 0;
    this.playbackStartedAt = null;
  };

  handleWindowResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  handleVideoResize = width => {
    this.setState({ currentVideoWidth: width });
  };

  handleVideoPropsReceived = ({ width, height }) => {
    this.setState({ videoFormat: width / height });
  };

  handleImageInputChange = () => {
    const { files } = this.fileInput;
    if (!files || files.length === 0) {
      return;
    }
    let file = files[0];
    let reader = new FileReader();
    reader.onload = event => {
      const imageUrl = event.target.result;
      this.registerSettingsChange('landingPageImage', imageUrl);
    };
    reader.readAsDataURL(file);
  };

  registerSettingsChange = (attribute, value) => {
    this.setState({
      newSettings: { ...(this.state.newSettings || {}), [attribute]: value }
    });
  };

  saveVideoSettings = async () => {
    const { title, description, landingPageImage } = this.state.newSettings;

    let landingPageImageUrl;
    if (landingPageImage) {
      landingPageImageUrl = await UploadApi.uploadDataUriImage(
        landingPageImage
      );
      this.setState({
        video: { ...this.state.video, landingPageImage: landingPageImageUrl }
      });
    }

    this.setState({
      newSettings: null
    });

    return VideosApi.updateVideo(
      this.videoId,
      title || null,
      null,
      null,
      description || null,
      landingPageImageUrl
    );
  };

  resetVideoSettings = () => {
    this.setState({ newSettings: null });
    this.descriptionNode.innerText = this.state.video.description;
    this.titleNode.innerText = this.state.video.title;
  };

  isEmbedded = () => this.props.match.url.indexOf('embed') !== -1;

  renderVideo = () => {
    const { video } = this.state;
    const { url, compiledUrl, thumbnailUrl, overlay, recordedBy } = video;

    const compiledMode = this.isCompiledMode && compiledUrl;
    const srcUrl = compiledMode ? compiledUrl : `${url}.mp4`;

    let containerStyle = {
      width: this.state.width,
      height: this.state.height,
      margin: 0,
      overflow: 'hidden'
    };

    if (!this.isEmbedded()) {
      const { videoFormat, currentVideoWidth } = this.state;
      const videoHeight =
        currentVideoWidth && videoFormat
          ? currentVideoWidth / videoFormat
          : null;
      containerStyle = {
        maxWidth: 560,
        width: '100',
        height: videoHeight || 315
      };
    }

    return (
      <div style={containerStyle}>
        <VideoPlayer
          playerRef={player => (this.player = player)}
          recordedBy={recordedBy}
          overlay={compiledMode ? {} : overlay}
          videoSrc={[srcUrl]}
          logo={
            video.merchant.profileImage && !compiledMode
              ? {
                  bottom: 50,
                  right: 50,
                  url: video.merchant.profileImage
                }
              : null
          }
          frameSource={compiledMode ? null : video.frameImageSource}
          poster={thumbnailUrl ? `${thumbnailUrl}-decorated` : null}
          onVideoPropsReceived={this.handleVideoPropsReceived}
          onResize={this.handleVideoResize}
          onPlay={this.onStartPlayback}
          onPause={this.onPausePlayback}
        />
      </div>
    );
  };

  renderFullLanding = () => {
    const { video, merchantMode, darkMode, newSettings } = this.state;
    let landingPageImage =
      video.landingPageImage || video.merchant.profileImage;
    if (newSettings && newSettings.landingPageImage) {
      landingPageImage = newSettings.landingPageImage;
    }

    return (
      <div className="message">
        <i
          className="dark-mode-control"
          onClick={() => {
            this.setState({ darkMode: !darkMode });
            document.body.classList.toggle('dark-mode');
          }}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </i>
        {merchantMode && (
          <React.Fragment>
            <Button
              width="xs"
              name="home"
              onClick={() => this.props.Routing.push('/')}
              style={{ position: 'fixed', top: 30, left: 30 }}
            />
            <Button
              width="xs"
              name="compiled video"
              onClick={() =>
                this.props.Routing.push(`/video/${video.id}/compiled`)
              }
              style={{ position: 'fixed', top: 30, left: 130 }}
            />
            {this.state.newSettings && (
              <>
                <Button
                  name="save changes"
                  width="xs"
                  style={{ position: 'fixed', top: 90, left: 30 }}
                  onClick={this.saveVideoSettings}
                />
                &nbsp;&nbsp;&nbsp;
                <Button
                  name="reset changes"
                  width="xs"
                  style={{ position: 'fixed', top: 90, left: 185 }}
                  onClick={this.resetVideoSettings}
                />
              </>
            )}
          </React.Fragment>
        )}
        <div className="cnt">
          <div className="message__limit">
            <div className="message__element" style={{ position: 'relative' }}>
              <img
                src={landingPageImage}
                className="landing-page-logo"
                alt={video.merchant.officeName}
              />
              {merchantMode && (
                <>
                  <input
                    ref={ref => (this.fileInput = ref)}
                    onChange={() => this.handleImageInputChange()}
                    className="file-input"
                    type="file"
                    accept="image/*"
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      opacity: 0,
                      width: '100%',
                      cursor: merchantMode ? 'pointer' : 'inherit'
                    }}
                  />
                  <p className="message__intro">
                    Click logo to select a new image.
                  </p>
                </>
              )}
            </div>

            <div className="message__element">
              {merchantMode ? (
                <div
                  className="message__title display-linebreak"
                  ref={titleNode => (this.titleNode = titleNode)}
                  dangerouslySetInnerHTML={{ __html: video.title }}
                  contentEditable={true}
                  onInput={() =>
                    this.registerSettingsChange(
                      'title',
                      getTextFromNode(this.titleNode)
                    )
                  }
                />
              ) : (
                <Linkify className="message__intro display-linebreak">
                  {video.title}
                </Linkify>
              )}
            </div>

            {this.renderVideo()}

            <div className="message__element">
              <SocialShare video={video} merchantMode={merchantMode} />
            </div>

            <div className="message__element" style={{ position: 'relative' }}>
              {merchantMode ? (
                <div
                  ref={descriptionNode =>
                    (this.descriptionNode = descriptionNode)
                  }
                  className="message__intro display-linebreak"
                  onInput={() =>
                    this.registerSettingsChange(
                      'description',
                      getTextFromNode(this.descriptionNode)
                    )
                  }
                  contentEditable={true}
                  placeholder="<enter landing page text>"
                  dangerouslySetInnerHTML={{ __html: video.description }}
                />
              ) : (
                <Linkify
                  className="message__intro display-linebreak"
                  style={{ display: 'block' }}
                >
                  {video.description}
                </Linkify>
              )}
            </div>
          </div>

          <div className="message__more" />

          <div className="message__footer">
            <div className="message__footer-left">
              <div>
                © {video.merchant.officeName} | All Rights Reserved,{' '}
                {new Date().getFullYear()}
              </div>
              <a href="">Privacy Policy</a>
              <a href="">Terms and Conditions</a>
            </div>
            <a href={MARKETING_HOST} style={{ color: '#bdc3cf' }}>
              powered by {BRAND}
            </a>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { video } = this.state;
    if (!video) {
      return (
        <div className="message">
          <div className="cnt">
            <div className="message__limit">
              <Loader />
            </div>
          </div>
        </div>
      );
    }

    if (!video.isPublished) {
      /** @todo Figure out better not found design */
      return <div>Page not found</div>;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{video.title}</title>
          <meta property="og:title" content={video.title} />
          <meta
            property="og:image"
            content={`${video.thumbnailUrl}-decorated`}
          />
        </Helmet>
        {this.isEmbedded() ? this.renderVideo() : this.renderFullLanding()}
      </React.Fragment>
    );
  }
}

VideoLanding.propTypes = {
  Routing: PropTypes.observableObject,
  match: ReactPropTypes.object
};

export default inject('Routing')(observer(withRouter(VideoLanding)));
