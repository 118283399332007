import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import { VideoSettings } from 'react-cam-recorder';
import { withRouter } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { VideosApi, UploadApi } from 'Api';
import Loader from 'Components/Loader/LineLoader';
import { apiError } from 'Utils/alert';
import { confirmYesNo } from 'Components/ConfirmModal';

class VideoEditor extends Component {
  state = {};
  get videoId() {
    const {
      match: {
        params: { videoId }
      }
    } = this.props;
    return videoId;
  }
  componentWillMount() {
    this.loadVideo(this.videoId);
  }

  loadVideo = async videoId => {
    const video = await VideosApi.getById(this.videoId);
    this.setState({
      video
    });
  };

  closeEditor = async () => {
    if (!(await confirmYesNo('Are you sure that you want to close editor?'))) {
      return;
    }
    this.props.Routing.push('/library/my-library/videos');
  };

  saveSettings = async settings => {
    let {
      videoTitle: title,
      videoCaption: caption,
      thumbnail: thumbnailUrl,
      decoratedThumbnail,
      decoratedThumbnailWithPlayButton
    } = settings;

    try {
      if (thumbnailUrl && thumbnailUrl.indexOf('data:') === 0) {
        thumbnailUrl = await UploadApi.uploadDataUriImage(thumbnailUrl);
        const thumbnailFileName = thumbnailUrl.split('/').pop();
        const decoratedThumbnailFileName = thumbnailFileName + '-decorated';
        const decoratedThumbnailWithPlayButtonFileName =
          thumbnailFileName + '-decorated-email';
        await Promise.all([
          UploadApi.uploadDataUriImage(
            decoratedThumbnail,
            () => {},
            decoratedThumbnailFileName
          ),
          UploadApi.uploadDataUriImage(
            decoratedThumbnailWithPlayButton,
            () => {},
            decoratedThumbnailWithPlayButtonFileName
          )
        ]);
      } else {
        thumbnailUrl = this.state.video.thumbnailUrl;
      }

      await VideosApi.updateVideo(this.videoId, title, caption, thumbnailUrl);
      await new Promise(r => {
        setTimeout(() => {
          r();
        }, 2000); //dirty hack, wait 2 seconds in order to make sure projection has been completed
      });

      setTimeout(
        () => this.props.Routing.push(`/video/${this.videoId}?preview`),
        100
      );
    } catch (e) {
      apiError(e);
    }
  };

  render() {
    const { video } = this.state;
    if (!video) {
      return <Loader />;
    }

    const { title, caption, url, thumbnailUrl, overlay } = video;

    const timestamp = new Date().getTime();
    return (
      <VideoSettings
        videoTitle={title || ''}
        videoCaption={caption || ''}
        videoSrc={[`${url}.mp4?${timestamp}`, `${url}?${timestamp}`]}
        videoThumbnail={thumbnailUrl ? `${thumbnailUrl}-decorated` : null}
        thumbnailMaxSize={1200}
        thumbnailQuality={0.7}
        decoration={{
          overlay: JSON.parse(JSON.stringify(overlay)),
          playButton: false,
          customFrameSource: video.frameImageSource
        }}
        onClose={this.closeEditor}
        onSave={this.saveSettings}
      />
    );
  }
}

VideoEditor.propTypes = {
  match: ReactPropTypes.object,
  Routing: PropTypes.observableObject
};

export default inject('Routing')(observer(withRouter(VideoEditor)));
